import * as React from 'react';
import { graphql } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';
import { Helmet } from 'react-helmet';
// import Link from '../components/Link/Link';

import Layout from "../components/layout"
import SEO from "../components/seo"

const defaultDescription = 'Edit this in your entry for a better SEO';
const defaultTitle = 'Olokoo Q2M';

function PageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const page = models.page[0]?.content;
//   const sitePreferences = models.sitePreferences[0];

    const locationObject = {
        pathname: page.data.url
    };

  return (
    <Layout
        location={ locationObject }
        title={ defaultTitle }>
        <SEO
        title={ (page && page.data.title) + ' - Niwot Inn & Spa' }
        description={ (page && page.data.description) || defaultDescription }
        />
        <BuilderComponent
            // renderLink={Link}
            name="page"
            content={page}
        />
    </Layout>
  );
}

export default PageTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      page(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;

// Backup including site preferences model
// export const pageQuery = graphql`
//   query($path: String!) {
//     allBuilderModels {
//       page(
//         target: { urlPath: $path }
//         limit: 1
//         options: { cachebust: true }
//       ) {
//         content
//       },
//       sitePreferences {
//         data {
//           siteName
//         }
//       }
//     }
//   }
// `;
